import React from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import { withRouter } from 'react-router-dom';
import { TileContainer, WellnessExpertImage, WellnessExpertName } from '../SocialFeeds/styles';
import { ImageUrl, imgPath } from '../../utils/constants';

const WellnessExpertSection = ({data, history, marginTop}) => (
  <div style={{display:"none"}}>
    <TileContainer width={marginTop ? 1 : 0} padding={1} profilePage={marginTop ? 1 : 0}
      onClick={() => history.push(`/wellness-expert/${data.id}`)}>
      <div>
        {
          isNull(data) ? null :
            <div>
              <div>
                <WellnessExpertImage src={`${ImageUrl}/${data.expert_picture}`} alt={data.expert_name}/>
                <div className="bg-opacity"/>
                <div className={"image"}>
                  <img src={`${imgPath}/wellnessevent.svg`} alt={'wellness expert'}/>
                </div>
                <div className={"featured-text"}>Featured wellness expert</div>
              </div>
              <WellnessExpertName>{data.expert_name}</WellnessExpertName>
            </div>
        }
      </div>
    </TileContainer>
  </div>
);

WellnessExpertSection.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object.isRequired,
  marginTop: PropTypes.bool
};

export default withRouter(WellnessExpertSection);
