/* eslint-disable react/jsx-key */
import React, { Component, Suspense, lazy } from "react";
import {
  Button,
  Resources,
  ResponsiveTestimonialTab,
  RowResponsiveWrapper,
  MenuListV2Main,
  CustomListItemV2Main,
  MainResources,
  Button2,
  TabData,
  WrapperContainer,
  RatingsContainers,
  CoreFeatureSection,
  ResourcesWrapper,
  HyperLink,
  RowWrapper,
  Divider,
  PaddingContainer,
  RatingWrapper,
  TabsContainerTestimonial,
  AwarenessSection,
  HyperLinkTab,
  WellBeing,
  DemoText,
  ButtonGetStarted,
  Wrapperr,
  RatingWrapperr,
} from "./styles";
import { ImageUrl, imgPath } from "../../utils/constants";
import PropTypes from "prop-types";
import data from "./wellnessResources.json";
import { MenuLinks } from "../NavigationBarV2/styles";
import {
  Section,
  IntroTextContainer,
  ButtonLearnMoree,
  PosterImage,
} from "./styles";
import { connect } from "react-redux";
import { subscribeNewsLetter } from "../../redux/actions/authActions";
import testimonialData from "./TestimonialData.json";
const DemoPopup = lazy(() => import("../WhyAmigoCommon/DemoPopup"));
import { ButtonWatchVideo } from "../RewardWebDesigns/styles";
import LazyImage from "../common/LazyImage/LazyImage";
import Waiting from './../Waiting/index'
class WellnessPage extends Component {
  constructor() {
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active: "",
      showVideo: false,
      selectedImage: "",
      selectedText: "WHY USERS LOVE US",
      selectedTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      image: [
        data[0].data[0].image,
        data[1].data[0].image,
        data[2].data[0].image,
        data[3].data[0].image,
        data[4].data[0].image,
        data[5].data[0].image,
      ],
      email: "",
      tabArray: [
        "Healthcare",
        "Technology",
        "Government",
        "Banking",
        "Insurance",
      ],
      ratingImage: [
        testimonialData[0].imageURL,
        testimonialData[1].imageURL,
        testimonialData[2].imageURL,
        testimonialData[3].imageURL,
        testimonialData[4].imageURL,
      ],
      ratingData: [
        testimonialData[0].data,
        testimonialData[1].data,
        testimonialData[2].data,
        testimonialData[3].data,
        testimonialData[4].data,
      ],
      ratingName: [
        testimonialData[0].head,
        testimonialData[1].head,
        testimonialData[2].head,
        testimonialData[3].head,
        testimonialData[4].head,
      ],
      ratingMain: [
        testimonialData[0].main,
        testimonialData[1].main,
        testimonialData[2].main,
        testimonialData[3].main,
        testimonialData[4].main,
      ],
      wellnessIndex: "",
      wellnessRowIndex: "",
    };
  }

  componentDidMount() {
    this.setState({ active: "Insurance" });
  }

  changeModal = () => {
    this.setState({ showVideo: !this.state.showVideo });
  };

  top = () => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
          WELLNESS
          <br />
          PERSONALIZED
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/HomePageV2/Rectangle-green.png"}/>
        <p>
          Save time and energy with an employee{" "}
          {this.state.mobileViewStatus ? "" : <br />}
          wellness tool that will support your efforts{" "}
          {this.state.mobileViewStatus ? "" : <br />}
          in enhancing workplace wellbeing and
          {this.state.mobileViewStatus ? "" : <br />}
          company morale.{this.state.mobileViewStatus ? "" : <br />}
        </p>
        <div className="wrap">
          <ButtonWatchVideo onClick={() => this.changeModal()}>
            <svg width="21" height="24" viewBox="0 0 136 153" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M129.381 65.0255C138.206 70.1255 138.206 82.8753 129.381 87.9752L20.1667 151.086C11.3413 156.186 0.30957 149.812 0.30957 139.612L0.309577 13.3884C0.309577 3.18855 11.3413 -3.18634 20.1667 1.91359L129.381 65.0255Z" fill="#9FC989"/>
            </svg>
            WATCH VIDEO
          </ButtonWatchVideo>
          <ButtonLearnMoree
            onClick={() => this.props.history.push("/contact")}
            noMargin={1}
          >
            REQUEST A DEMO
          </ButtonLearnMoree>
        </div>
      </IntroTextContainer>
      <PosterImage>
        <picture>
          <source
            srcSet={ImageUrl + "/images/WellnessPage/Wellness_banner.webp"}
            type="image/png"
          />
          <LazyImage src={ImageUrl + "/images/WellnessPage/Wellness_banner.webp"} alt={"wellness-banner"}/>
        </picture>
      </PosterImage>
      <LazyImage src={ImageUrl + "/images/WellnessPage/wellnessMobileBanner.png"} title="Poster Image" alt={"wellness-banner"}/>
    </Section>
  );

  ratingshead = () => (
    <RatingWrapperr>
      <Wrapperr>
        <h1>Loved by Clients</h1>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </Wrapperr>
    </RatingWrapperr>
  );

  ratingsUI = () => (
    <RatingWrapper>
      <RatingsContainers>
        <LazyImage div={"image"} src={ImageUrl + this.state.ratingImage[this.state.selectedTab]} alt={"rating"} />
        <LazyImage div={"ratingStars"} src={ImageUrl + "/images/HomePageV2/ratingStars.png"} alt={"rating"} />
        <div className={"ratingDescription"}>
          {this.state.ratingData[this.state.selectedTab]}
        </div>
        <div className={"ratingBy"}>
          {this.state.ratingName[this.state.selectedTab]}{" "}
          <span>{this.state.ratingMain[this.state.selectedTab]}</span>
        </div>
      </RatingsContainers>
    </RatingWrapper>
  );

  decreaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab - 1;
    this.setState({ selectedTab: temp });
  };

  increaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab + 1;
    this.setState({ selectedTab: temp });
  };

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  };

  renderMenuList = () => {
    let TabArray = [
      {
        tabName: "Healthcare",
        image: "/path/",
        text: "Healthcare data",
      },
      {
        tabName: "Technology",
        image: "/path/",
        text: "Technology Data",
      },
      {
        tabName: "Government",
        image: "/path/",
        text: "Civil Engineering data",
      },
      {
        tabName: "Banking",
        image: "/path/",
        text: "Banking data",
      },
      {
        tabName: "Insurance",
        image: "/path/",
        text: "Insurance data",
      },
    ];

    return (
      <TabsContainerTestimonial>
        <MenuListV2Main>
          {TabArray.map((data, index) => (
            <CustomListItemV2Main
              showTab={true}
              active={this.state.selectedTab === index}
              key={index}
              onClick={() => this.handleTabs(index)}
            >
              <MenuLinks>{data.tabName}</MenuLinks>
            </CustomListItemV2Main>
          ))}
        </MenuListV2Main>
      </TabsContainerTestimonial>
    );
  };

  renderTabData = () => (
    <TabData>
      <div className="responsiveWhyButton">
        <Button2
          display={"block"}
          onClick={() => this.props.history.push("/contact")}
        >
          BOOK A DEMO
        </Button2>
      </div>
      <LazyImage src={ImageUrl + "/images/WellnessPage/why_user_love_us.webp"} />
      <div>
        <h2>WHY EMPLOYEES LOVE US</h2>
        <h3>
          Wellness Is Gamified, Social,
          {this.state.mobileViewStatus ? "" : <br />} Mobile And Inclusive
        </h3>
        <LazyImage src={ImageUrl + "/images/HomePageV2/blueDivider.png"} />
        <p>
          Give your employees a personalized mobile
          {this.state.mobileViewStatus ? "" : <br />} wellness program with
          fitness training, daily tips,
          {this.state.mobileViewStatus ? "" : <br />}
          nutrition recipes, wellness education, and more!
        </p>
        <Button2
          display={"block"}
          onClick={() => this.props.history.push("/contact")}
        >
          REQUEST A DEMO
        </Button2>
      </div>
    </TabData>
  );

  handleResources = (resIndex, rowIndex) => {
    const { wellnessResources, image } = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status === 1 && 0;
    });
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image;
    this.setState({ wellnessResources: array, image: images });
  };

  // handleResources = (resIndex, rowIndex) => {
  //   const {wellnessResources, image, wellnessIndex, wellnessRowIndex} = this.state;
  //   let array = wellnessResources;
  //   if(wellnessIndex==resIndex && wellnessRowIndex===rowIndex){
  //     array[wellnessIndex].data[wellnessRowIndex].status = array[wellnessIndex].data[wellnessRowIndex].status?0:1;
  //   }
  //   else{
  //     if(wellnessIndex!=='')
  //       array[wellnessIndex].data[wellnessRowIndex].status=0;
  //     array[resIndex].data[rowIndex].status = 1;
  //   }
  //   let images = image;
  //   images[resIndex] = wellnessResources[resIndex].data[rowIndex].image;
  //   this.setState({wellnessResources: array, image: images, wellnessIndex: resIndex, wellnessRowIndex: rowIndex });
  // }

  multipleFeatures = () => (
    <ResourcesWrapper>
      {this.state.wellnessResources.length > 0 &&
        this.state.wellnessResources.map((row, index) => (
          <MainResources key={index} color={row.color}>
            <h3>{row.heading}</h3>
            <LazyImage div={"line"} src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
            <Resources
              flexDirection={row.swapRow}
              col={row.color}
              align={index % 2 == 0 ? "flex-end" : "flex-start"}
            >
              <div>
                <div>
                  <LazyImage src={ImageUrl +"/images/" + `${row.icon}`} alt={row.title}/>
                  <h4 className={"title"}>{row.title}</h4>
                </div>
                <div className="normal">
                  {row.data.map((info, rowindex) => (
                    <RowWrapper
                      key={rowindex}
                      selected={info.status}
                      bgColor={row.bgColor}
                      onClick={() => this.handleResources(index, rowindex)}
                      customColor={info.status === 1 ? 1 : 0}
                    >
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div>{info.header}</div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {info.status === 1 && (
                        <div className={"expand"}>{info.description}</div>
                      )}
                    </RowWrapper>
                  ))}
                  <HyperLinkTab>
                    <HyperLink
                      onClick={() => this.props.history.push(row.history)}
                    >
                      Learn more about {row.title}{" "}
                    </HyperLink>
                    <LazyImage height={"14px"} width={"8px"} src={ImageUrl + "/images/WellnessPage/rightArrow.png"}                         
                      alt={"arrow"}
                      onClick={() => this.props.history.push(row.history)}/> 
                  </HyperLinkTab>
                </div>
                <div className="responsive">
                  {row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapper
                        key={rowindex}
                        selected={info.status}
                        bgColor={row.bgColor}
                        onClick={() => this.handleResources(index, rowindex)}
                        customColor={info.status === 1 ? 1 : 0}
                      >
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {info.status === 1 && (
                          <div className={"expand"}>{info.description}</div>
                        )}
                      </RowResponsiveWrapper>
                      {info.status === 1 && (
                        <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>
                      )}
                    </div>
                  ))}
                  <HyperLinkTab>
                    <HyperLink
                      onClick={() => this.props.history.push(row.history)}
                    >
                      Learn more about {row.title}{" "}
                    </HyperLink>
                    <LazyImage height={"14px"} width={"8px"} src={ImageUrl + "/images/WellnessPage/rightArrow.png"}                         
                      alt={"arrow"}
                      onClick={() => this.props.history.push(row.history)}/> 
                  </HyperLinkTab>
                </div>
              </div>
              <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>
            </Resources>
          </MainResources>
        ))}
    </ResourcesWrapper>
  );
  coreFeatureSection = () => (
    <CoreFeatureSection>
      <div>
        <h2>WellBeing Resources</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"} />
      </div>
    </CoreFeatureSection>
  );

  awarenessUI = () => (
    <AwarenessSection>
      <div className="responsiveAwarenessButton"></div>
      <LazyImage div={"img"} src={ImageUrl + "/images/WellnessPage/Group.svg"} /> 
      <div className="text">
        <p>
          Of employees using Woliba
          <br />
          {"say we've helped them increase"}
          <br />
          awareness of their personal
          <br />
          health.
        </p>
        <div className="responsivetext">
          <p>
            {
              "Employees using Woliba say we've helped them increase their awareness for personal health."
            }
          </p>
        </div>
        <Button
          display={"block"}
          onClick={() => this.props.history.push("/contact")}
        >
          GET STARTED
        </Button>
      </div>
    </AwarenessSection>
  );

  wellBeingSection = () => (
    <WellBeing background={"rgb(253, 113, 117)"}>
      <div style={{ background: "rgb(253, 113, 117)" }}>
        <div>
          <p style={{ marginBottom: 0 }}>
            Want to see the full <br />
            Woliba experience?
          </p>
          <DemoText>
            Request a demo to meet with one of our
            <br />
            wellness specialists.
          </DemoText>
          <ButtonGetStarted
            display={"block"}
            onClick={() => this.props.history.push("/contact")}
          >
            Schedule A Live Demo
          </ButtonGetStarted>
        </div>
      </div>

      <LazyImage src={ImageUrl + "/images/HomePageV2/ScheduleademoNew.webp"} />

    </WellBeing>
  );

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  divider = () => (
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"} />
    </Divider>
  );
  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email,
    };
    if (email.trim() !== "") {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: "",
      });
    }
  };

  renderResponsiveTab = () => (
    <ResponsiveTestimonialTab>
      {this.state.selectedTab > 0 ? 
        this.state.selectedTab > 0 && <LazyImage div="leftArrow" onClick={() => this.decreaseIndex()} src={ImageUrl + "/images/HomePageV2/leftArrow.png"} />
        : <div className="leftArrow"></div>}
      <div>
        <p>
          {
            this.state.tabArray[this.state.selectedTab]
          }
        </p>
      </div>
      {this.state.selectedTab < 4 && 
          this.state.selectedTab < 4 && <LazyImage div="rightArrow" onClick={() => this.increaseIndex()} src={ImageUrl + "/images/HomePageV2/rightArrow.png"} /> }
    </ResponsiveTestimonialTab>
  );

  render() {
    const { showVideo } = this.state;
    return (
      <React.Fragment>
        <WrapperContainer>
          {this.top()}
          {/* {this.ratingshead()} */}
          {this.ratingsUI()}
          {this.renderResponsiveTab()}
          <PaddingContainer>{this.renderMenuList()}</PaddingContainer>
          {this.renderTabData()}
          {this.awarenessUI()}
          <PaddingContainer>{this.coreFeatureSection()}</PaddingContainer>
          <PaddingContainer>{this.multipleFeatures()}</PaddingContainer>
          {this.wellBeingSection()}
        </WrapperContainer>
        <Suspense fallback={<Waiting/>}>
          {showVideo && (
            <DemoPopup
              showModal={showVideo}
              onClose={this.changeModal}
              videoUrl={"https://vimeo.com/810186247"}
              VideoTitle={"Woliba Corporate Wellness"}
            />
          )}
        </Suspense>
      </React.Fragment>
    );
  }
}
WellnessPage.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) =>
    dispatch(subscribeNewsLetter(history, payload)),
});
export default connect(null, mapDispatchToProps)(WellnessPage);
