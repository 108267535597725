import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import { Layout, Title, ProfileSection, ProfileInformation, WellnessJourney, ExerciseInformation, ExerciseSection, AccomplishmentInformation,
  Accomplishments, InfoTitle, Section, Image } from './styles';
import Waiting from '../Waiting';
import { ImageUrl } from '../../utils/constants';
import { getWellnessExpert } from '../../redux/actions';
import { Interweave } from 'interweave';

class WellnessExpertDetails extends Component {

  componentDidMount() {
    const { fetchWellnessExpert } = this.props;
    fetchWellnessExpert();
  }

  render() {
    const { wellnessExpert } = this.props;
    if(isNull(wellnessExpert)) {
      return <Waiting />
    }
    return (
      <Layout>
        <Title>{wellnessExpert.expert_name}</Title>
        <ProfileSection>
          <div>
            <Image src={`${ImageUrl}/${wellnessExpert.expert_picture}`} alt={wellnessExpert.expert_name} />
          </div>
          <ProfileInformation>
            <h3>{wellnessExpert.expert_name}</h3>
            <h4>Wellness Expert</h4>
            {
              wellnessExpert.training_philosophy &&
              <Section>
                <InfoTitle>Training Philosophy</InfoTitle>
                <div>{wellnessExpert.training_philosophy}</div>
              </Section>
            }
            {
              wellnessExpert.expert_quote &&
              <Section>
                <InfoTitle>Quote to live by</InfoTitle>
                <div>{wellnessExpert.expert_quote}</div>
              </Section>
            }
          </ProfileInformation>
        </ProfileSection>
        <WellnessJourney>
          <h2>My Wellness Journey</h2>
          <div>{wellnessExpert.expert_journey}</div>
        </WellnessJourney>
        <ExerciseSection>
          {
            wellnessExpert.expert_exercise_picture &&
            <div>
              <Image src={`${ImageUrl}/${wellnessExpert.expert_exercise_picture}`} alt="outside-exercise" />
            </div>
          }
          <ExerciseInformation isLongContainer={!wellnessExpert.expert_exercise_picture}>
            {
              wellnessExpert.expert_exercise &&
              <Section>
                <InfoTitle>Outside of exercise I stay healthy by...</InfoTitle>
                <div>{wellnessExpert.expert_exercise}</div>
              </Section>
            }
            {
              wellnessExpert.expert_vegetable &&
              <Section>
                <InfoTitle>My spirit vegetable is...</InfoTitle>
                <div>{wellnessExpert.expert_vegetable}</div>
              </Section>
            }
          </ExerciseInformation>
        </ExerciseSection>
        <Accomplishments>
          {
            wellnessExpert.expert_certification_picture &&
            <div>
              <Image src={`${ImageUrl}/${wellnessExpert.expert_certification_picture}`} alt="accomplishments" />
            </div>
          }
          <AccomplishmentInformation isLongContainer={!wellnessExpert.expert_certification_picture}>
            <h4>As a Woliba Wellness Expert...</h4>
            <div>
              <InfoTitle>I have also accomplished the following...</InfoTitle>
              {
                <Interweave content={wellnessExpert.expert_certifications} />
              }
            </div>
          </AccomplishmentInformation>
        </Accomplishments>
      </Layout>
    )
  }
}

WellnessExpertDetails.propTypes = {
  fetchWellnessExpert: PropTypes.func.isRequired,
  wellnessExpert: PropTypes.object
};

const mapStateToProps = (state) => ({
  wellnessExpert: state.social.wellnessExpert,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWellnessExpert: () => dispatch(getWellnessExpert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WellnessExpertDetails);