import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  padding: 30px 30px 40px;
  float: left;

  h3, h4, h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    padding: 30px 10px 40px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 300px;
`;

const Title = styled.h1`
  text-transform: capitalize;
  font-weight: normal;
  color: #898989;
  margin: 10px 0;
  font-size: 30px;

  @media (max-width: 700px) {
    text-align: center;
  }
`;

const ProfileSection = styled.div`
  margin-top: 35px;
  margin-bottom: 50px;
  float: left;
  width: 100%;

  > div:first-child {
    display: inline-block;
    width: 300px;
    text-align: center;
    float: left;

    @media (max-width: 700px) {
      width: 100%;
      max-width: none;
    }
  }

  @media (max-width: 700px) {
    display: block;
  }
`;

const ProfileInformation = styled.div`
  margin-left: 30px;
  width: calc(100% - 330px);
  background-color: white;
  color: rgb(101, 104, 111);
  padding: 30px;
  display: inline-block;
  float: left;

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    margin: 15px 0 0;
    width: 100%;
  }
`;

const WellnessJourney = styled.div`
  width: 100%;
  margin-bottom: 50px;
  white-space: pre-line;
  background: #fff;
  padding: 30px;
  float: left;

  h2 {
    color: #65686f;
    font-size: 25px;
    text-align: center;
  }

  div {
    font-size: 15px;
  }
`;

const ExerciseSection = styled.div`
  width: 100%;
  margin-bottom: 50px;
  float: left;

  > div:first-child {
    display: inline-block;
    width: 300px;
    text-align: center;
    float: right;

    @media (max-width: 700px) {
      width: 100%;
      max-width: none;
    }
  }

  @media (max-width: 700px) {
    display: block;
  }
`;

const ExerciseInformation = styled.div`
  width: ${({ isLongContainer }) => isLongContainer ? '100%' : 'calc(100% - 330px)'};
  margin-right: ${({ isLongContainer }) => isLongContainer ? '0' : '30px'};
  padding: 30px;
  background-color: white;
  display: inline-block;
  float: left;

  @media (max-width: 700px) {
    margin: 15px 0 0;
    width: 100%;
  }
`;

const Accomplishments = styled.div`
  width: 100%;
  margin-bottom: 50px;
  float: left;

  > div:first-child {
    display: inline-block;
    width: 300px;
    text-align: center;
    float: left;

    @media (max-width: 700px) {
      width: 100%;
      max-width: none;
    }
  }

  @media (max-width: 700px) {
    display: block;
  }
`;

const AccomplishmentInformation = styled.div`
  width: ${({ isLongContainer }) => isLongContainer ? '100%' : 'calc(100% - 330px)'};
  margin-left: ${({ isLongContainer }) => isLongContainer ? '0' : '30px'};
  padding: 30px;
  background-color: white;
  display: inline-block;
  float: left;
  h4 {
    text-align: center;
  }

  > div {
    > div {
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  @media (max-width: 700px) {
    margin: 15px 0 0;
    width: 100%;
  }
`;

const InfoTitle = styled.p`
  color: rgb(101, 104, 111);
  font-weight: bold;
`;

const Section = styled.div`
  margin-bottom: 15px;
`;

export { Layout, Title, ProfileSection, ProfileInformation, WellnessJourney, ExerciseSection, ExerciseInformation, AccomplishmentInformation, Accomplishments,
  InfoTitle, Section, Image };